import ttsController from 'shared/foreground/actions/ttsController.platform';
import contentFrame from 'shared/foreground/portalGates/contentFrame/to/reactNativeWebview';
import type { FirstClassDocument } from 'shared/types';
import { UNCHUNKED_DOCUMENT_CHUNK_ID } from 'shared/types/chunkedDocuments';

export default async function playOrStopTtsFromCurrentScrollPosition({
  currentlyOpenDocId,
}: {
  currentlyOpenDocId: FirstClassDocument['id'];
}) {
  const positionResult = await contentFrame.getPositionForTtsFromCurrentScrollPositionUsingIds(
    'document-text-content',
    'document-reader-root',
    // TODO: We need to get the actual chunk ID programmatically when web chunking works
    UNCHUNKED_DOCUMENT_CHUNK_ID,
  );
  if (!positionResult) {
    return;
  }

  let startingPosition = positionResult.position;
  if (typeof positionResult.elementIndex !== 'undefined') {
    const positionForElement = await ttsController.fetchTimestampForElement(
      currentlyOpenDocId,
      // TODO: We need to get the actual chunk ID programmatically when web chunking works
      UNCHUNKED_DOCUMENT_CHUNK_ID,
      positionResult.elementIndex,
    );
    if (positionForElement && positionForElement > 0) {
      startingPosition = positionForElement;
    }
  }

  ttsController.playOrStopDocument({
    docId: currentlyOpenDocId,
    // TODO: We need to get the actual chunk ID programmatically when web chunking works
    chunkId: UNCHUNKED_DOCUMENT_CHUNK_ID,
    startingPosition,
  });
}
