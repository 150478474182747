import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { createCurrentHeadingFinder, makeTocPathnameSet, shouldUseCustomAttributes } from 'shared/foreground/tableOfContents';
import { useHasDocumentContentInitialized } from 'shared/foreground/useHasDocumentContentInitialized';
import {
  forceChunkContentLoadForContainer,
  getHeadingElement,
} from 'shared/foreground/utils/chunkedScrolling';
import combineClasses from 'shared/foreground/utils/combineClasses';
import { findChunkContainerForNode } from 'shared/foreground/utils/findChunkContainerForNode';
import { EpubToc } from 'shared/types';
import exceptionHandler from 'shared/utils/exceptionHandler.platform';

import { useIsLeftSidebarHidden } from '../hooks/hooks';
import { scrollToDocElement } from '../utils/scrollToDocElement';
import styles from './TableOfContents.module.css';

const TocItem = memo(function TocItem({
  tocItem,
  isActive,
  leftSidebarHidden,
  onItemClick,
}: {
  tocItem: EpubToc;
  isActive: boolean;
  leftSidebarHidden: boolean;
  onItemClick: (tocItem: EpubToc) => void;
}) {
  const itemId = tocItem.id;
  const name = tocItem.title;

  const className = combineClasses([
    styles.listItemWrapper,
    isActive ? styles.active : '',
    styles[`listItemLevel${tocItem.level}`],
  ]);

  const listItemClassName = leftSidebarHidden ? styles.hidden : '';

  return (
    <button
      type="button"
      onClick={() => onItemClick(tocItem)}
      className={className}
      key={itemId}
    >
      <div className={styles.bar} />
      <li className={listItemClassName}>{name}</li>
    </button>
  );
});

// Takes an element and generates a table of contents. It will update the content if needed
export default function EpubTableOfContents({
  contentRoot,
  tocItems,
  currentFocusedElement = null,
  isChunked,
  isEpub,
}: {
  contentRoot: HTMLElement;
  tocItems: EpubToc[];
  currentFocusedElement: HTMLElement | null;
  isChunked: boolean;
  isEpub: boolean;
}): JSX.Element | null {
  const [activeId, setActiveId] = useState('');
  const leftSidebarHidden = useIsLeftSidebarHidden();
  const hasDocumentContentInitialized = useHasDocumentContentInitialized();

  const tocPathnameSet = useMemo(() => makeTocPathnameSet(tocItems), [tocItems]);

  const headings = useMemo(
    () => Array.from(contentRoot.querySelectorAll('[data-rw-epub-toc]')),
    // need to wait for chunked content to load before querying content root.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentRoot, hasDocumentContentInitialized],
  );

  const useCustomAttributes = useMemo(() => shouldUseCustomAttributes(contentRoot), [contentRoot]);

  const getCurrentHeading = useMemo(
    () => createCurrentHeadingFinder({ isChunked, tocPathnameSet, isEpub, useCustomAttributes }),
    [isChunked, tocPathnameSet, isEpub, useCustomAttributes],
  );

  useEffect(() => {
    if (!currentFocusedElement || !contentRoot || !headings.length) {
      return;
    }

    const currentChunkContainer = findChunkContainerForNode(currentFocusedElement, contentRoot);

    if (!currentChunkContainer) {
      return;
    }

    const currentHeadingId = getCurrentHeading(currentFocusedElement, currentChunkContainer);

    if (currentHeadingId) {
      setActiveId(currentHeadingId);
    }
  }, [currentFocusedElement, contentRoot, headings, getCurrentHeading]);

  const handleTocItemClick = useCallback(async (tocItem: EpubToc) => {
    const idToScrollTo = isChunked ? tocItem.href : tocItem.id;
    const headingElement = await getHeadingElement(idToScrollTo, isEpub, contentRoot, forceChunkContentLoadForContainer);

    if (!headingElement) {
      exceptionHandler.captureException('Could not find heading element', { extra: { idToScrollTo, isChunked, isEpub } });
      return;
    }

    await scrollToDocElement(headingElement, isChunked);

    setActiveId(idToScrollTo);
  }, [isChunked, isEpub, contentRoot]);

  const items = useMemo(() =>
    tocItems.map((tocItem: EpubToc): JSX.Element => {
      const idToScrollTo = isChunked ? tocItem.href : tocItem.id;
      const isActive = activeId === idToScrollTo;

      return (
        <TocItem
          key={tocItem.id}
          tocItem={tocItem}
          isActive={isActive}
          leftSidebarHidden={leftSidebarHidden}
          onItemClick={handleTocItemClick}
        />
      );
    }),
    [tocItems, activeId, isChunked, leftSidebarHidden, handleTocItemClick]);

  if (!headings.length) {
    return null;
  }

  return (
    <div className={`${styles.root}`}>
      <h4 id="toc-title" className={`${styles.title} ${leftSidebarHidden ? styles.hidden : ''}`}>
        Contents
      </h4>
      <ol>{items}</ol>
    </div>
  );
}
