import { startChunkContainerIntersectionObservers, stopChunkContainerIntersectionObservers } from 'shared/foreground/contentFramePortalGateInternalMethods';

import { getScrollContainer } from './getScrollContainer';

export async function scrollToDocElement(element: HTMLElement, isChunked: boolean) {
  if (isChunked) {
    await stopChunkContainerIntersectionObservers();
  }

  const elementPosition = element?.getBoundingClientRect().top;
  const offset = 100;

  if (elementPosition !== undefined) {
    const scrollContainer = getScrollContainer();

    scrollContainer?.scrollBy({
      top: elementPosition - offset,
      behavior: 'instant',
    });
  }

  if (isChunked) {
    await startChunkContainerIntersectionObservers();
  }
}
