import {
  type BaseDocument,
  type FirstClassDocument,
  type ReadingPosition,
  type UserEvent,
  TtsPosition,
} from '../../../../types';
import { isFirstClassDocument } from '../../../../typeValidators';
// eslint-disable-next-line import/no-cycle
import { updateReadingPositionInDocData, updateTTSPositionInDocData } from '../../../stateUpdateHelpers';
import { createToast } from '../../../toasts.platform';
import { updateDocument } from './update';

export const updateReadingPosition = async (
  docId: BaseDocument['id'],
  position: ReadingPosition,
  options: Parameters<typeof updateDocument>[2] & {
    force?: boolean;
    showToast?: boolean;
    toastContent?: string;
    allowExperienceGain?: boolean;
  },
): Promise<void> => {
  const correlationId = Math.random().toString();
  const updateResult = await updateDocument(
    docId,
    (doc) => {
      updateReadingPositionInDocData(
        doc,
        position,
        {
          force: options.force ?? false,
          allowExperienceGain: options?.allowExperienceGain ?? false,
          correlationId,
        },
      );
    },
    { ...options, correlationId, isUndoable: options.isUndoable },
  );

  if (options.showToast || options.toastContent) {
    const content =
      options.toastContent ||
      (position.scrollDepth === 1 ? 'Marked as read' : 'Reading position updated');
    createToast({
      content,
      category: 'success',
      undoableUserEventId: options.isUndoable ? (updateResult.userEvent as UserEvent).id : undefined,
      eventToEmitOnUndo: 'reading-position-update-undone',
    });
  }
};

export const updateScrollPosition = async (
  docId: BaseDocument['id'],
  position: ReadingPosition,
  options: Parameters<typeof updateDocument>[2],
): Promise<void> => {
  await updateDocument(
    docId,
    (doc) => {
      doc.currentScrollPosition = position;
    },
    { ...options, isUndoable: false },
  );
};

export const resetReadingProgress = (docId: FirstClassDocument['id']) =>
  updateReadingPosition(
    docId,
    {
      scrollDepth: 0,
      serializedPosition: null,
    },
    {
      eventName: 'document-progress-position-reset',
      force: true,
      showToast: true,
      userInteraction: 'click',
    },
  );

// When Track Player updates tts position, it must also update reading progress.
// It is best to do so in one go, to minimize background processes.
export const updateDocumentTTSPosition = async (
  docId: BaseDocument['id'],
  chunkId: string | undefined,
  ttsPosition: TtsPosition | undefined,
  readingPosition: ReadingPosition | undefined,
  options: Parameters<typeof updateDocument>[2],
): Promise<void> => {
  await updateDocument(
    docId,
    (doc) => {
      if (!isFirstClassDocument(doc)) {
        return;
      }
      updateTTSPositionInDocData(chunkId, doc, ttsPosition);
      if (readingPosition) {
        updateReadingPositionInDocData(doc, readingPosition, { force: false, allowExperienceGain: true });
      }
    },
    { ...options, isUndoable: options.isUndoable },
  );
};
