import { EpubToc } from '../types';
import { ChunkContainerElement } from './types/chunkedDocuments';
import getNextElementWithinContainer from './utils/getNextNodeWithinContainer';

type GetCurrentHeadingOptions = {
  isChunked: boolean;
  tocPathnameSet: Set<string>;
  isEpub: boolean;
  useCustomAttributes: boolean;
};

export const HEADING_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
export const HEADING_TAG_SELECTOR = HEADING_TAGS.join(', ');
const HEADING_ID_PREFIX = 'h__';
export const EPUB_HEADING_SELECTOR = '[data-rw-epub-toc]';

export function getHeadingSelector(isEpub: boolean): string {
  if (isEpub) {
    return EPUB_HEADING_SELECTOR;
  } else {
    return HEADING_TAG_SELECTOR;
  }
}

export function createHeadingIdFromIndex(index: number): string {
  return `${HEADING_ID_PREFIX}${index}`;
}

export function getHeadingQuerySelector(isEpub: boolean, useCustomAttributes: boolean): string {
  if (useCustomAttributes) {
    return '[data-rw-toc-level]';
  } else if (isEpub) {
    return '[data-rw-epub-toc]';
  } else {
    return HEADING_TAG_SELECTOR;
  }
}

export function parseHeadingIdToIndex(hash: string): number | undefined {
  const index = parseInt(hash.replace(`#${HEADING_ID_PREFIX}`, ''), 10);
  if (Number.isNaN(index)) {
    return undefined;
  }
  return index;
}

export function parseHeadingLevel(heading: HTMLElement, useCustomAttributes = false) {
  if (useCustomAttributes) {
    return parseInt(heading.dataset?.rwTocLevel || '1', 10);
  } else {
    return parseInt(heading.tagName.substring(1), 10);
  }
}


/**
 * Returns a function that can be used to find the current heading from a given element.
 */
export function createCurrentHeadingFinder({ isChunked, tocPathnameSet, isEpub, useCustomAttributes }: GetCurrentHeadingOptions) {
  const hasChunkedTocId = (el: Element, chunkFilename: string) => {
    if (!isChunked) {
      return false;
    }

    const filePathWithFragment = getFullTocHref(el, chunkFilename);
    if (!filePathWithFragment) {
      return false;
    }
    return tocPathnameSet.has(filePathWithFragment);
  };

  const hasChunkedTocDataAttribute = (el: Element) => {
    const dataToc = el.getAttribute('data-rw-epub-toc');

    if (!dataToc) {
      return false;
    }

    return tocPathnameSet.has(dataToc);
  };

  const isHeading = (el: Element) => {
    const selector = getHeadingQuerySelector(isEpub, useCustomAttributes);
    if (selector === HEADING_TAG_SELECTOR) {
      return HEADING_TAGS.includes(el.tagName.toLowerCase());
    } else {
      return el.matches(selector);
    }
  };

  function getCurrentHeadingId(headingElement: HTMLElement, chunkFilename: string) {
    if (hasChunkedTocId(headingElement, chunkFilename)) {
      return getFullTocHref(headingElement, chunkFilename);
    }

    if (isHeading(headingElement) || hasChunkedTocDataAttribute(headingElement)) {
      return isEpub
      ? headingElement.getAttribute('data-rw-epub-toc')
      : headingElement.id;
    }

    return null;
  }

  function getClosestHeading({
    container,
    element,
    chunkFilename,
  }: {
    container: HTMLElement;
    element: HTMLElement;
    chunkFilename: string;
  }): HTMLElement | undefined {
    const viewportHeight = window.innerHeight;

    const isFullyVisible = (el: Element) => {
      const rect = el.getBoundingClientRect();
      return rect.top >= 0 && rect.bottom <= viewportHeight;
    };

    const isTOCHeading = (el: Element) => isChunked
    ? hasChunkedTocId(el, chunkFilename) || hasChunkedTocDataAttribute(el)
    : isHeading(el);

    const isFullyVisibleHeading = (el: Element) => isFullyVisible(el) && isTOCHeading(el);


  // First, we try to find elements that are above the centered element inside of the viewport
    const previousElement = getNextElementWithinContainer({
      container,
      direction: 'previous',
      element,
      matcher: isFullyVisibleHeading,
      checkParents: true,
    }) as HTMLElement | undefined;

  // If there is a visible element above the centered element inside of the viewport
  // we always prefer that as the closest heading.
    if (previousElement) {
      return previousElement;
    }

  // For some chunked docs, the next heading is not at the very top of the viewport, because the
  // pagination breaks at the chunk level. See the EPUB "Onyx Storm" for an example.
  // In this case, we try to find the next heading below that is visible in the viewport.
    const nextElement = getNextElementWithinContainer({
      container,
      direction: 'next',
      element,
      matcher: isFullyVisibleHeading,
      checkParents: true,
    }) as HTMLElement | undefined;

    if (nextElement) {
      return nextElement;
    }

  // If no visible elements found, fall back to just finding any matching element above the centered element
    return getNextElementWithinContainer({
      container,
      direction: 'previous',
      element,
      matcher: isTOCHeading,
      checkParents: true,
    }) as HTMLElement | undefined;
  }

  return function getCurrentHeading(centeredElement: HTMLElement, chunkContainer: ChunkContainerElement): string | undefined {
    const contentRoot: HTMLDivElement | null = document.querySelector('#document-text-content');
    if (!centeredElement || !contentRoot) {
      return;
    }

    if (!chunkContainer) {
      return;
    }
    const chunkFilename = chunkContainer.dataset.chunkFilename;

    const headingId = getCurrentHeadingId(centeredElement, chunkFilename);

    if (headingId) {
      return headingId;
    }

    const closestHeading = getClosestHeading({
      container: contentRoot,
      element: centeredElement,
      chunkFilename,
    });

    if (closestHeading) {
      const closestHeadingId = getCurrentHeadingId(closestHeading, chunkFilename);
      if (closestHeadingId) {
        return closestHeadingId;
      }
    }

    // If the current element is not within a chapter, we use the first chapter in the TOC instead.
    // Some epubs have content that precedes the first chapter, so we don't want to return the first
    // heading in the TOC as the current heading.
    if (isChunked) {
      const [firstTocPathname] = Array.from(tocPathnameSet);
      return firstTocPathname || undefined;
    } else {
      const selector = getHeadingQuerySelector(isEpub, useCustomAttributes);
      const firstHeadingElement = contentRoot.querySelector(selector) as HTMLElement | null;

      if (firstHeadingElement) {
        return getCurrentHeadingId(firstHeadingElement, '') || undefined;
      }
    }
  };
}


export function makeTocPathnameSet(tocItems: EpubToc[] | undefined) {
  if (!tocItems) {
    return new Set<string>();
  }
  return new Set(tocItems.map((item) => item.href));
}

export function shouldUseCustomAttributes(contentRoot: HTMLElement) {
  return Boolean(contentRoot.querySelectorAll('[data-rw-toc-level]').length);
}

function getFullTocHref(el: Element, chunkFilename: string) {
  if (!el.id) {
    return undefined;
  }
  return `${chunkFilename}#${el.id}`;
}
